.questionsCont,
.question,
.answersCont {
    display: flex;
    flex-direction: column;
}

.questionsCont {
    row-gap: 20px;
}

.question {
    row-gap: 10px;
}

.answersCont {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
}

.answersCont.pdf {
    flex-direction: column;
    gap: 3px;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
}

.answer {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    min-width: 32px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    padding: 0px 12px;
    background: var(--primary-light-gray);
    color: var(--text-light-gray-secondary);
    transition: all 0.2s;
    border: 1px solid transparent;
}

.answer.active {
    background-color: var(--dark-gray);
    color: var(--white);
}

.answer.blocked {
    pointer-events: none;
}

.answer.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
    cursor: default;
}

.answer.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
    cursor: default;
}

.answer.pdf {
    pointer-events: none;
    position: relative;
    margin-left: 32px;
    width: fit-content;
    height: 20px;
    min-height: 20px;
    padding: 0 6px;
}

.answer.pdf::before {
    width: 12px;
    height: 12px;
    border: 1px solid var(--text-light-gray-secondary);
    border-radius: 50%;
    content: '';
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translate(-100%, -50%);
}

@media (hover: hover) {
    .answer:hover {
        background-color: var(--secondary-light-gray);
    }
    .answer.active:hover {
        background-color: var(--night-gray);
    }
}

@media print {
    .question {
        break-inside: avoid;
    }
}
