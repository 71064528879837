.learnContainer,
.modalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerInner {
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden scroll;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--primary-light-gray);
}
