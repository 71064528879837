.container {
    width: 100vw;
    height: max-content;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--light-gray);
    z-index: 0;
}

.pdfContainer {
    width: 210mm;
    height: fit-content;
    min-height: 297mm;
    margin: 10px auto;
    background: var(--white);
    padding: 20px 50px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 4px 4px var(--black-transparent);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
}

.logo {
    display: flex;
    gap: var(--_6px);
    margin-left: var(--_4px);
    margin-top: var(--_6px);
    position: relative;
    width: 100px;
    min-width: 100px;
    cursor: pointer;
    margin-right: auto;
}

.teacherLogo {
    width: 32px;
    height: 32px;
    margin-top: -4.5px;
}

.teacherLogoText {
    margin-top: 1px;
    transform: scale(0.8);
}

.previewControls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;
}

.exerciseTitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: -12px;
}

.exerciseSubtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.exerciseTitle span {
    font-size: 28px;
    font-weight: 400;
    line-height: 34px;
    color: var(--text-light-gray);
    margin-left: 10px;
}

.completedByLabel {
    display: none;
    font-size: 12px;
}

.exerciseContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.exerciseContainer > div {
    height: min-content;
}

.bottomContainer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.printButton {
    margin-left: auto;
}

.pdfIcon {
    margin-left: -12px;
    min-width: 20px;
    min-height: 20px;
}

.platformButton,
.mediaButton {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 32px;
    padding: 12px 24px;
    border-radius: 8px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--white);
}

.platformButton {
    display: none;
    background: var(--purple);
}

.mediaButton {
    background: var(--blue);
    margin: 0 auto 12px;
}

.buttonLogo {
    filter: invert(1);
    min-width: 16px;
    min-height: 16px;
    margin-left: -4px;
}

.footer {
    width: calc(100% + 100px);
    margin-left: -50px;
    padding: 0 50px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer p {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin: 0 auto;
    color: var(--text-light-gray);
}

.exerciseBody * {
    font-size: 12px !important;
}

.mediaIcon {
    margin-right: 6px;
    margin-left: -6px;
    width: 16px;
    height: 20px;
    min-width: 16px;
    min-height: 20px;
}

.noAccessContainer {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 36px;
    width: 80%;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.alertTitle {
    text-align: center;
    color: var(--text-light-gray-secondary);
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@media print {
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    .pdfContainer {
        margin: 0 auto;
        box-shadow: none;
    }
    @page {
        margin: 0;
        padding: 50px 20px;
    }
    body {
        margin: 0;
    }
    .printButton,
    .previewControls {
        display: none;
    }
    .platformButton {
        display: flex;
    }
    .completedByLabel {
        display: block;
    }
}
