.modal {
    height: fit-content;
}

.modal.user {
    height: 200px;
}

.bottomButton {
    margin-top: auto;
}
