.registerContainer {
    background: var(--light-gray);
    position: relative;
    max-width: 100vw;
    flex-grow: 1;
    overflow: scroll;
}

.container {
    background: var(--white);
    width: 45%;
    min-width: 550px;
    max-width: 1000px;
    height: 90vh;
    position: relative;
    margin: auto;
    flex-grow: 1;
}

.containerInner {
    width: 100%;
    height: 90vh;
    padding: 6% 12%;
    margin: auto;
    display: flex;
    gap: 62px;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--white);
    z-index: 2;
    position: relative;
    overflow: scroll;
}

.numbersLeft,
.numbersRight {
    position: absolute;
    width: 100vw;
    height: 80vh;
    min-height: 600px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.numbersRight {
    animation-delay: 3s;
}

.numbersLeft {
    left: 5%;
    background-image: url('../../assets/webp/numbers1.webp');
    z-index: 1;
    animation: moveUp 4s ease-in-out infinite;
}

.numbersRight {
    right: 5%;
    background-image: url('../../assets/webp/numbers2.webp');
    z-index: 1;
    animation: moveDown 6s ease-in-out infinite;
}

.form {
    display: flex;
    gap: 0;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
}

.form > div > p {
    color: var(--text-light-gray-secondary);
}

.registrationTitle {
    font-family: Taler;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
    white-space: nowrap;
    width: 120%;
    margin-left: -10%;
}

.signInButton,
.signInButton span {
    text-align: left;
    font-family: Noto Sans;
    font-size: 12px;
    white-space: nowrap;
    color: var(--text-light-gray-secondary);
}

.signInButton span {
    font-weight: 600;
    cursor: pointer;
    color: var(--purple);
    text-decoration: underline;
}

.errorMessage {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--primary-red);
    font-family: Noto Sans;
    white-space: nowrap;
    font-size: 12px;
}

.buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.verificationInfo,
.email {
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.email {
    color: var(--purple);
}

.roleText {
    padding: 72px 0 24px;
    line-height: 24px;
    text-align: center;
}

.roleButtons {
    display: flex;
    gap: 24px;
    padding-bottom: 48px;
    width: 100%;
}

.roleButton {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    padding: 24px;
    max-height: unset;
}

.teacherInvite {
    padding: 12px 0 24px;
    font-size: 12px;
    text-align: center;
    color: var(--purple);
}

@keyframes moveDown {
    0% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(calc(-50% + 8px));
    }
    100% {
        transform: translateY(-50%);
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(calc(-50% - 6px));
    }
    100% {
        transform: translateY(-50%);
    }
}
