.toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    width: 100%;
    gap: 16px;
    border-radius: 12px;
    background: var(--primary-light-gray);
}

.toolbar.words {
    background: var(--words-green-transparent);
}

.toolbar.track {
    background: var(--purple-transparent);
}

.toolbar.exercise {
    background: var(--blue-transparent);
}

.toolbar.situation {
    background: var(--orange-light);
}

.toolbar > *:last-child {
    margin-left: auto;
}

.separator {
    width: 1px;
    height: 16px;
    background: var(--border-light-gray);
    display: block;
}

.control {
    min-width: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}

.control > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.control:last-child {
    margin-right: 8px;
}

.strokeAdjust path {
    stroke-opacity: 0.6;
}
