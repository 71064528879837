.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-overlay-background);
    backdrop-filter: blur(2px);
    z-index: 9;
}

.lessonModal {
    padding: 20px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    width: 97vw;
    height: 97vh;
    max-height: 97vh;
    box-sizing: border-box;
    overflow: hidden;
    left: 1.5vw;
    top: 1.5vh;
    position: absolute;
    background: var(--light-gray);
    border-radius: 24px;
    z-index: 10;
}

.lessonModal.unfinished {
    background: var(--unfinished-status-color);
}

.lessonModal.done {
    background: var(--light-green);
}

.addButtons {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    margin-top: -12px;
}

.mainContainer {
    display: flex;
    gap: 12px;
    width: 100%;
    overflow: visible hidden;
    flex-grow: 1;
}

.aside {
    max-width: calc(40% - 12px);
    min-width: calc(40% - 12px);
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
}

.chaptersList {
    width: 100%;
    border: 1px solid var(--border-light-gray);
    background: var(--white);
    border-radius: 12px;
    position: relative;
    overflow: visible scroll;
    flex-grow: 1;
}

.scrollContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: max-content;
}

.container {
    max-width: 60%;
    min-width: 60%;
    max-height: 100%;
    overflow: hidden scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    box-sizing: border-box;

    transition:
        all 0.2s,
        border none;
    position: relative;
}

.container.situation,
.container.track,
.container.words {
    background: var(--white);
    border-radius: 12px;
}

.container.situation,
.container.track {
    border: 1px solid var(--border-light-gray);
}

.emptyLessonContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 80%;
}

.emptyAlert svg path {
    fill: var(--text-light-gray);
}

.alertTitle,
.alertSubtitle {
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.alertTitle {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
}

.alertSubtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

@media (hover: hover) {
    .addButton:hover {
        background: var(--secondary-light-gray);
    }
}
