.gap {
    cursor: pointer;
    margin-bottom: 3px;
    line-break: anywhere;
    user-select: none;
    font-size: 12px;
}

.gap.selected {
    background-color: var(--primary-light-gray);
    border-radius: 5px;
    color: var(--black);
    padding: 2px 9px;
}

.dropdown_gap,
.custom_gap,
.keyboard_gap {
    height: 18px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: -5px;
    filter: invert(100%);
    user-select: none;
    background-color: transparent;
}

.gap.selected * {
    vertical-align: middle;
}

.tooltip {
    position: absolute;
    z-index: 5;
    display: none;
    width: 200px;
    height: min-content;
    padding: 6px 0;
    box-sizing: border-box;
    background: var(--white);
    box-shadow: 0px 0px 7px 0px var(--black-transparent);
    border-radius: 16px;
    color: var(--dark-gray);
    font-size: 12px;
    user-select: none;
    cursor: default;
}

.gap.active .tooltip {
    display: block;
}

.tooltipCont {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    row-gap: 4px;
    padding: 0 14px;
}

.tooltipCont span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
}

.tooltipCont::-webkit-scrollbar {
    width: 0;
}

.tooltipCont .inputComponent {
    flex: 0 0 36px;
    padding-right: 26px;
}

.correctOption {
    width: 100%;
    padding: 10px 15px;
    margin: 0;
    background-color: var(--secondary-light-gray);
    color: var(--dark-gray);
    height: 37px;
    max-height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 17px;
    outline: none;
    cursor: default;
}

.optionLine {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inputCont {
    position: relative;
}

.inputCont:hover .deleteIcon {
    visibility: visible;
    opacity: 100;
}

.input {
    width: 100%;
    padding: 0px 8px;
    margin: 0;
    background-color: var(--white);
    border: 1px solid var(--primary-light-gray);
    height: 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    outline: none;
    flex-shrink: 0;
    flex-grow: 0;
}

.input.blur {
    background-color: var(--transparent-gray);
    border: 1px solid var(--secondary-light-gray);
    color: var(--dark-gray);
    transition: all 0.2s;
    cursor: pointer;
}

.input.disabled {
    pointer-events: none;
    cursor: default;
}

.deleteIcon {
    visibility: hidden;
}

.deleteIcon.focus {
    visibility: visible;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons > * {
    margin-bottom: 0;
}

.unselectButton {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-light-gray-secondary);
    position: relative;
    margin-left: 10px;
    transition: color 0.2s;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
}

.iconCross {
    position: absolute;
    content: url('../../../../../../../../assets/svg/icon-close.svg');
    opacity: 0.9;
    cursor: pointer;
    width: 10px;
    height: 10px;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    filter: brightness(1);
}

@media (hover: hover) {
    .gap:hover {
        background: var(--secondary-light-gray);
    }

    .gap.selected:hover {
        background-color: var(--secondary-light-gray);
    }

    .input.blur:hover {
        background-color: var(--secondary-light-gray);
        border: 1px solid var(--secondary-light-gray);
    }

    .dropdown_gap:hover,
    .custom_gap:hover,
    .keyboard_gap:hover {
        background-color: transparent;
    }

    .unselectButton:hover {
        color: var(--black);
    }
    .iconCross:hover {
        filter: brightness(1.5);
    }
}
