.profitCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    height: 100%;
    gap: var(--_16px);
    border-radius: var(--_16px);
    padding: var(--_20px);
    position: relative;
    z-index: 2;
}

.orange {
    background-color: #ffaa5d1a;
}

.blue {
    background-color: #2f83ea1a;
}

.turquoise {
    background-color: #42dfa71a;
}

.heliotrope {
    background-color: #b770ff1a;
}

.textBlock {
    display: flex;
    flex-direction: column;
    gap: var(--_16px);
    height: 100%;
}

.textBlock > p:nth-child(2) {
    height: var(--_58px);
}

.index {
    background-color: #ffffff;
    border-radius: var(--_8px);
    min-width: var(--_36px);
    max-width: var(--_36px);
    min-height: var(--_36px);
    max-height: var(--_36px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--_16px);
}

.title {
    font-size: var(--_22px);
    font-weight: 400;
    line-height: var(--_24px);
    letter-spacing: -0.01em;
}

.subtitle,
.subtitle span {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.01em;
    color: #1c1c1eb2;
}

.subtitle span {
    font-weight: 500;
    color: var(--black);
}

.exampleBlock {
    background-color: transparent;
    margin-top: auto;
    border-radius: var(--_10px);
    height: 37%;
    min-height: 37%;
    max-height: 37%;
    overflow: hidden;
}

.orange > .exampleBlock {
    border: 1px solid #ffaa5d66;
}

.blue > .exampleBlock {
    border: 1px solid #2f83ea66;
}

.turquoise > .exampleBlock {
    border: 1px solid #42dfa766;
}

.heliotrope > .exampleBlock {
    border: 1px solid var(--dialogue-progress-purple);
}

.aspiringTeacher {
    display: flex;
    flex-direction: column;
    padding: var(--_12px);
    gap: var(--_8px);
    justify-content: center;
}

.aspiringTeacher > div {
    display: flex;
    background-color: #ffffff;
    border-radius: var(--_12px);
    padding: var(--_8px) var(--_10px);
    gap: var(--_10px);
    box-shadow: 0px 3.88px 26.1px 0px #ff79001a;
    max-width: 75%;
    width: 100%;
}

.aspiringTeacher > div:nth-child(2) {
    margin-left: auto;
}

.img,
.firstImage {
    flex-shrink: 0;
}

.firstImage {
    width: var(--_64px);
    height: var(--_32px);
    overflow: hidden;
    border-radius: var(--_6px);
    background-image: url('../../../../assets/png/aspiring-teacher-1.png');
    background-size: cover;
}

.aspiringTeacher_textBlock {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    gap: var(--_3px);
}

.gray {
    font-size: var(--_9px);
    font-weight: 400;
    line-height: var(--_10px);
    color: #3c3c434d;
}

.block_title {
    font-size: var(--_10px);
    font-weight: 400;
    line-height: var(--_10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

.specialist {
    padding: var(--_12px);
}

.single {
    max-width: 100%;
}

.cardContent {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: var(--_11px);
    padding: var(--_11px);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    max-height: 100%;
    overflow-y: auto;
    max-height: calc(100% - var(--_32px));
}

.cardTrack {
    display: flex;
    flex-direction: column;
}

.cardTrack > p {
    font-size: var(--_12px);
}

.cardTrack div {
    display: flex;
    gap: var(--_4px);
    align-items: center;
    color: #3c3c4399;
}

.cardTrack div p {
    color: #3c3c4399;
    font-size: var(--_10px);
}

.cardTasks {
    display: flex;
    flex-direction: column;
    gap: var(--_4px);
    max-width: 100%;
}

.task {
    background-color: #fafaff;
    border-radius: var(--_4px);
    max-width: 100%;
    display: flex;
    padding: var(--_9px);
    gap: var(--_8px);
}

.task svg {
    flex-shrink: 0;
}

.task p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    font-size: var(--_13px);
    font-weight: 400;
    line-height: var(--_16px);
}

.student {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}

.progressBar {
    position: absolute;
    right: 0px;
    bottom: var(--_8px);
}

.experiencedTeacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--_24px);
    position: relative;
}

.experiencedTeacher_block {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 70%;
    padding: var(--_12px);
    background-color: #ffffff;
    border-radius: var(--_8px);
    box-shadow: 0px 3.42px 22.97px 0px #2f83ea1a;
}

.experiencedTeacher_block > p {
    font-size: var(--_11px);
    font-weight: 400;
    line-height: var(--_16px);
    letter-spacing: -0.01em;
    padding-left: var(--_6px);
}

.experiencedTeacher_block > svg:last-child {
    margin-left: auto;
}

.experiencedTeacher_block:nth-child(1) {
    transform: rotate(-5.22deg) translateX(-10px);
}

.experiencedTeacher_block:nth-child(2) {
    transform: rotate(4.61deg) translateX(10px);
}

.experiencedTeacher_check {
    position: absolute;
    right: calc(-1 * var(--_40px));
    transform: rotate(-15.84deg) scale(1.5);
}

.experiencedTeacher_star {
    position: absolute;
    left: calc(-1 * var(--_30px));
    transform: rotate(-4.61deg) translateY(35px);
}
