.statementControls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    width: calc(100% - 28px);
    left: 14px;
    height: 0px;
    overflow: visible;
}

.arrowButtonLeft,
.arrowButtonRight {
    width: 44px;
    min-width: 44px;
    padding: 0;
    height: 44px;
    max-height: 44px;
    border-radius: 50%;
    box-shadow: 0px 3px 8px 0px var(--black-transparent);
    position: relative;
}

.arrowButtonLeft {
    margin-right: auto;
}

.arrowButtonRight {
    margin-left: auto;
}

.arrowIconLeft,
.arrowIconRight {
    height: 16px;
    min-height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.arrowIconLeft {
    transform: translate(-50%, -50%) rotate(-90deg);
}

.arrowIconRight {
    transform: translate(-50%, -50%) rotate(90deg);
}

.arrowIconLeft path,
.arrowIconRight path {
    stroke: var(--black);
    stroke-opacity: 1;
}
