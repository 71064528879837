.questionsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.questionCont {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    position: relative;
    align-items: center;
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
    height: fit-content;
    flex-grow: 1;
}

.pdfOptions {
    display: flex;
    flex-wrap: nowrap;
}

.pdfAnswer {
    position: relative;
    margin-left: 32px;
    text-wrap: nowrap;
}

.pdfAnswer::before {
    width: 12px;
    height: 12px;
    border: 1px solid var(--text-light-gray-secondary);
    border-radius: 50%;
    content: '';
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translate(-100%, -50%);
}

@media (hover: hover) {
    .answer:hover {
        background-color: var(--secondary-light-gray);
    }
    .answer.active:hover {
        background-color: var(--text-light-green);
    }
    .answer.correct:hover {
        background-color: var(--primary-green);
    }
}

@media print {
    .questionCont {
        break-inside: avoid;
    }
}
