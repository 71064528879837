.button {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 32px;
    padding: 12px 24px;
    position: relative;
    border-radius: 8px;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    transition: all 0.2s;
    cursor: pointer;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.button span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    pointer-events: none;
}

.button.large .icon {
    width: 22px;
    min-width: 22px;
}

.button.tiny {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 5.86px;
}

.icon {
    width: 10px;
    min-width: 10px;
}

.button.white {
    background: var(--white);
    color: var(--black);
}

.button.burgundy {
    background: var(--pdf-burgundy);
    color: var(--white);
}

.button.white.pressed:not([disabled]) {
    background: var(--white-pressed);
}

.button.burgundy.pressed:not([disabled]) {
    background: var(--pdf-burgundy-pressed);
}

.button.grey {
    background: var(--primary-light-gray);
    color: var(--black);
}

.button.grey.pressed:not([disabled]) {
    background: var(--primary-light-gray-pressed);
}

.button.dark {
    background: var(--night-gray);
    color: var(--white);
}

.button.dark.pressed:not([disabled]) {
    background: var(--black);
}

.button.red {
    background: var(--primary-light-gray);
    color: var(--primary-red);
}

.button.red.pressed:not([disabled]) {
    background: var(--primary-light-gray-pressed);
}

.button.purple {
    background: var(--purple);
    color: var(--white);
}

.button.purple.pressed:not([disabled]) {
    background: var(--purple-pressed);
}

.button.lightOrange {
    background: var(--orange-transparent);
    border: 1px solid var(--orange);
}

.button.lightOrange.pressed:not([disabled]) {
    background: var(--orange-transparent-pressed);
}

.button.darkPurple {
    background: var(--dialogue-accent-purple);
    color: var(--white);
}

.track {
    background: var(--purple);
    color: var(--white);
}

.track path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.exercise {
    background: var(--blue);
    color: var(--white);
}

.exercise path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.situation {
    background: var(--orange);
    color: var(--white);
}

.situation path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.words {
    background: var(--words-green);
    color: var(--white);
}

.words path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.bordo {
    background: var(--pdf-burgundy);
    color: var(--white);
}

.navy {
    background: var(--hyperlink-blue);
    color: var(--white);
}

.button[disabled] {
    filter: grayscale(1);
    background: var(--transparent-gray);
    color: var(--text-disabled);
    cursor: not-allowed;
}

.button.pressed:not([disabled]) {
    transition: none;
}

.button[disabled] .icon {
    opacity: 0.2;
}

.button.track.progress {
    background-color: var(--purple-transparent);
}

.button.words.progress {
    background-color: #42dfa89c;
}

.button.situation.progress {
    background-color: var(--orange-light);
}

.button.exercise.progress {
    background-color: #2f83ea5b;
}

.button.progress::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    animation: fill-animation var(--animation-duration) linear forwards;
    animation-delay: var(--animation-delay);
    border-radius: 8px;
    z-index: 0;
}

.button.progress span {
    position: relative;
    z-index: 1;
}

.button.track.progress::after {
    background-color: var(--purple);
}

.button.words.progress::after {
    background-color: var(--words-green);
}

.button.situation.progress::after {
    background-color: var(--orange);
}

.button.exercise.progress::after {
    background-color: var(--blue);
}

.button.darkPurple.pressed {
    background: var(--dialogue-accent-purple);
}

@keyframes fill-animation {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

@media (hover: hover) {
    .button.white:hover:not([disabled]):not(.pressed) {
        background: var(--white-hover);
    }

    .button.grey:hover:not([disabled]):not(.pressed) {
        background: var(--primary-light-gray-hover);
    }

    .button.red:hover:not([disabled]):not(.pressed) {
        background: var(--primary-light-gray-hover);
    }

    .button.dark:hover:not([disabled]):not(.pressed) {
        background: var(--black-hover);
    }

    .button.purple:hover:not([disabled]):not(.pressed) {
        background: var(--purple-hover);
    }

    .button.burgundy:hover:not([disabled]):not(.pressed) {
        background: var(--pdf-burgundy-hover);
    }

    .button.lightOrange:hover:not([disabled]):not(.pressed) {
        background: var(--orange-transparent-hover);
    }

    .button.darkPurple:hover:not([disabled]):not(.pressed) {
        background: var(--dialogue-accent-purple-hover);
    }

    .button:disabled:hover {
        animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(1px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-2px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(2px, 0, 0);
    }
}
