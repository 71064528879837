.button {
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    width: min-content;
    position: relative;
    white-space: nowrap;
    user-select: none;
    -webkit-user-drag: none;
    cursor: pointer;
    transition:
        all 0.2s,
        transform 0s;
    font-family: Noto Sans;
    text-align: center;
    height: 28px;
    min-width: 56px;
    min-height: 56px;
    padding: 5px 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: var(--white);
}

.button.words {
    height: 100%;
    width: 52px;
    border-radius: 0;
}

.button svg {
    min-width: 16px;
    min-height: 16px;
}

.button.words.purple {
    background: var(--dialogue-accent-purple);
}

.button.words.green {
    background: var(--green);
}

.button.words.black {
    background: var(--night-gray);
}

.button.words.red {
    background: var(--primary-red);
}

.button.purple {
    background: var(--dialogue-accent-purple);
}

.button.green {
    background: var(--green);
}

.button.orange {
    background: var(--accent-orange);
}

.button.black {
    background: var(--night-gray);
}

.button.red {
    background: var(--primary-red);
}

.button.disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: not-allowed;
}

.iconCheck path {
    stroke: var(--white);
}

@media (hover: hover) {
    .button:not(.disabled):hover {
        filter: brightness(1.1) contrast(1.1);
    }
}
