div.confirmModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 680px;
    height: fit-content;
    max-height: 600px;
    overflow: hidden;
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 16px;
    position: relative;
    min-width: 250px;
    max-width: 1090px;
}

.confirmModal > div {
    justify-content: center;
    overflow: hidden;
}

.wordsList {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    padding: 12px 24px 24px;
    max-height: calc(70% - 32px);
    overflow: scroll;
}

.wordsSection {
    overflow: hidden;
    margin: auto 0;
}

.buttonsSection {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.buttonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

.buttonsContainer > button {
    width: 100%;
}

.buttonsContainer svg {
    min-width: 16px;
    min-height: 20px;
    margin-left: -8px;
    margin-right: 8px;
}
