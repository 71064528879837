.learnWords {
    width: 100%;
    position: relative;
    border: 1px solid var(--border-light-gray);
    background: var(--primary-light-gray);
    border-radius: 12px;
    overflow: hidden;
}

.learnWords::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
}

.learnWords.continue::before {
    background: var(--green-transparent);
}

.learnWords.repeat::before {
    background: var(--primary-transparent-red);
}

.cardsCont,
.resultsCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 90%;
    max-width: 650px;
    background: var(--white);
    border-radius: 32px;
    box-shadow: 0px 2px 46px 8px var(--black-transparent);
    z-index: 2;
}

.resultsCont::before,
.resultsCont::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        90deg,
        var(--words-green),
        var(--words-green-transparent),
        var(--white),
        var(--words-green),
        var(--words-green-transparent),
        var(--white)
    );
    background-size: 400%;
    z-index: -1;
    animation: glow 10s linear infinite;
    width: 100%;
    border-radius: 28px;
    opacity: 0.5;
}

.resultsCont::after {
    filter: blur(12px);
    transform: translate3d(0, 0, 0);
}

.alertCont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardsCont {
    overflow: hidden;
}

.resultsContInner {
    width: 100%;
    height: 100%;
    background: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    padding: 20px;
}

.resultsContInner p {
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    white-space: pre-line;
}

.resultsContInner .subtitle {
    font-size: 14px;
    margin-top: 12px;
    line-height: 20px;
}

.starIcon {
    width: 100px;
    height: 100px;
}

.starIcon path {
    fill: var(--words-green);
    stroke: transparent;
}

.loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cardControls {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    left: 0;
    top: 0;
}

.repeatButton,
.continueButton {
    width: 50%;
    height: 100%;
    position: relative;
    cursor: pointer;
    overflow: visible;
    opacity: 0;
    transition: opacity 0.2s;
}

.repeatButton::before,
.continueButton::before {
    content: '';
    position: absolute;
    top: 0;
    width: 200%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.repeatButton::before {
    left: 0;
}

.continueButton::before {
    right: 0;
}

.repeatButton::before {
    background: linear-gradient(
        to left,
        transparent 0%,
        var(--primary-transparent-red) 50%
    );
}

.continueButton::before {
    background: linear-gradient(
        to right,
        transparent 0%,
        var(--primary-transparent-green) 50%
    );
}

.repeatButton:hover::before,
.continueButton:hover::before {
    opacity: 0.9;
}

.buttonInner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 5px;
}

.repeatButton .buttonInner {
    left: 7%;
}

.continueButton .buttonInner {
    right: 7%;
}

.buttonInner p {
    font-family: Noto Sans;
    font-size: 20px;
    font-weight: 20px;
    line-height: 24px;
    color: var(--text-light-gray);
    white-space: nowrap;
    transition: all 0.2s;
}

.buttonInner svg {
    width: 16px;
    transition: all 0.2s;
}

.buttonInner path {
    stroke: var(--text-light-gray);
    transition: all 0.2s;
}

.repeatButton svg {
    transform: rotate(180deg);
}

.wordsList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 20px 0;
}

.wordsList span {
    font-size: 22px;
}

.learnedList {
    font-weight: 500;
}

.cardTitle {
    position: absolute;
    padding-top: 12px;
    left: 10px;
    width: calc(100% - 20px);
    text-align: center;
    color: var(--text-light-gray);
    background: var(--white);
    z-index: 3;
}

@media (hover: hover) {
    .cardsCont:hover + .cardControls .repeatButton,
    .cardsCont:hover + .cardControls .continueButton {
        opacity: 1;
    }

    .repeatButton:hover,
    .repeatButton:hover .repeatButton::before {
        opacity: 1;
    }

    .continueButton:hover p,
    .continueButton:hover path {
        color: var(--primary-green);
        stroke: var(--primary-green);
    }

    .repeatButton:hover p,
    .repeatButton:hover path {
        color: var(--primary-red);
        stroke: var(--primary-red);
    }

    .continueButton:hover,
    .continueButton:hover .continueButton::before {
        opacity: 1;
    }
}

@keyframes glow {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }

    100% {
        background-position: 0 0;
    }
}
