.rangeContainer {
    width: 100%;
    max-width: 316px;
    height: 32px;
    padding: 0 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.range {
    -webkit-appearance: none;
    margin: 0 auto;
    width: 100%;
    height: 32px;
    border-radius: 6px;
    background-color: var(--purple);
    cursor: pointer;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 36px;
    height: 34px;
    background-color: var(--white);
    box-shadow: 0px 0px 3px 3px var(--black-transparent);
    border-radius: 6px;
    cursor: pointer;
}

.range::-moz-range-track,
.range::-ms-track {
    background-color: var(--purple);
    height: 32px;
}

.range::-ms-fill-lower,
.range::-ms-fill-upper {
    background-color: var(--purple);
}

.label {
    width: 36px;
    height: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
    pointer-events: none;
    z-index: 3;
}

.label:first-child {
    left: 2px;
    color: var(--white);
}

.label:last-child {
    right: 2px;
    color: var(--black);
}

.currentValueContainer {
    position: absolute;
    width: calc(100% - 40px);
    left: 2px;
    height: 32px;
    pointer-events: none;
}

.currentValue {
    position: absolute;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 300;
    width: 36px;
    height: 34px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    line-height: 34px;
    color: var(--black);
    background: var(--white);
    z-index: 4;
    border-radius: 6px;
}
