.category {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    padding-top: 16px;
    height: fit-content;
    position: relative;
    height: 100%;
}

.category.outlined {
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.packTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.microButtons {
    position: relative;
    display: flex;
    gap: 6px;
    z-index: 3;
}

.iconStroke path {
    stroke: var(--medium-gray);
    stroke-opacity: 1;
}

.wordsList {
    display: flex;
    flex-direction: column;
    width: calc(40% - 24px);
    overflow: scroll;
}

.word {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 12px;
    border-radius: 4px;
    background: var(--white);
    cursor: pointer;
    min-height: 38px;
    position: relative;
    user-select: none;
    transition: all 0.2s;
    position: relative;
}

.word span {
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    position: absolute;
    color: var(--text-light-gray);
}

.word p,
.category > div input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    margin-left: 24px;
}

.word.word.invalid p {
    color: var(--text-light-red);
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 0px;
    margin-bottom: -20px;
    position: relative;
}

.inputContainer p {
    font-size: 16px;
    color: var(--text-light-gray);
    padding: 8px 12px;
    padding-right: 5px;
}

.inputContainer > div:last-child {
    height: 30px;
    min-height: 30px;
    width: 40%;
    min-width: calc(40% - 24px);
}

.inputContainer > div:last-child * {
    font-size: 16px;
}

.controls {
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}

.word.active {
    background: var(--white-hover);
}

.emptyAlert {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
}

.infoText {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-light-gray-secondary);
    width: 100%;
}

.horizontalContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.horizontalContainer > div {
    width: 100%;
}

.deleteModalInner {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.wordsList {
    text-align: center;
}

.loaderCont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    height: fit-content;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    padding: 0 24px;
}

.editorContainer {
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    gap: 20px;
}

@media (hover: hover) {
    .addWordButton:hover,
    .word:hover {
        background: var(--light-gray);
    }
}

@media (hover: hover) {
    .word.active:hover .controls,
    .word.pending:hover .controls,
    .word.invalid:hover .controls {
        opacity: 1;
    }
}
