.previewContainer {
    background-color: var(--white);
    border: 1px solid var(--border-light-gray);
    border-radius: var(--_12px);
    padding: var(--_20px);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--_42px);
    overflow: scroll;
    scroll-behavior: smooth;
}

.studentContainer {
    background-color: var(--white);
    border-radius: var(--_12px);
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--_42px);
}

.category {
    display: flex;
    flex-direction: column;
    gap: var(--_20px);
    height: fit-content;
    position: relative;
    height: 100%;
}

.title {
    width: calc(50% - 24px);
    font-size: var(--_24px);
    font-weight: 400;
    line-height: var(--_36px);
    line-break: anywhere;
}

.line {
    width: calc(50% - 24px);
    height: 1px;
    background: var(--border-light-gray);
}

.wordsList {
    display: flex;
    flex-direction: column;
    width: calc(50% - 24px);
    overflow: scroll;
}

.word {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: var(--_6px) var(--_12px);
    border-radius: var(--_4px);
    background: var(--white);
    cursor: pointer;
    min-height: var(--_32px);
    position: relative;
    user-select: none;
    transition: all 0.2s;
    position: relative;
}

.word:last-child {
    margin-bottom: var(--_150px);
}

.word span {
    font-size: var(--_12px);
    font-weight: 400;
    line-height: var(--_24px);
    text-align: left;
    position: absolute;
    color: var(--text-light-gray);
}

.word p,
.category > div input::placeholder {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_24px);
    text-align: left;
    margin-left: var(--_24px);
}

.word.word.invalid p {
    color: var(--text-light-red);
}

.inputContainer {
    display: flex;
    align-items: center;
    gap: 0;
    margin-bottom: calc(-1 * var(--_20px));
    position: relative;
}

.inputContainer p {
    font-size: var(--_16px);
    color: var(--text-light-gray);
    padding: var(--_8px) var(--_12px);
    padding-right: var(--_6px);
}

.inputContainer > div:last-child {
    height: var(--_30px);
    min-height: var(--_30px);
    width: calc(50% - var(--_24px));
    min-width: calc(50% - var(--_24px));
}

.inputContainer > div:last-child * {
    font-size: var(--_16px);
}

.controls {
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    top: 50%;
    right: var(--_12px);
    transform: translateY(-50%);
}

.word.active {
    background: var(--white-hover);
}

.emptyAlert {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--_12px);
}

.infoText {
    text-align: center;
    font-size: var(--_12px);
    line-height: var(--_16px);
    color: var(--black);
    width: 100%;
}

.horizontalContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: var(--_6px);
}

.horizontalContainer > div {
    width: 100%;
}

.deleteModalInner {
    display: flex;
    flex-direction: column;
    gap: var(--_24px);
}

.wordsList {
    text-align: center;
}

.loaderCont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wordCard {
    display: flex;
    width: calc(50% - var(--_20px));
    flex-direction: column;
    gap: var(--_8px);
    padding: var(--_20px);
    border-radius: var(--_12px);
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 2px 7px 0px #0000001f;
    height: fit-content;
    min-height: 400px;
    max-height: 90%;
    height: fit-content;
    position: absolute;
    right: var(--_20px);
    top: 5%;
    background: var(--white);
    overflow: scroll;
}

.close {
    position: absolute;
    top: var(--_12px);
    right: var(--_12px);
    filter: grayscale(1);
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;
}

.wordText {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_20px);
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.wordText span {
    width: 100%;
    font-size: var(--_32px);
    font-weight: 500;
    line-height: var(--_40px);
    text-align: center;
}

.errorStatus {
    font-weight: 400;
    font-size: var(--_12px);
    line-height: var(--_12px);
    color: var(--primary-red);
}

.partOfSpeech {
    font-size: var(--_14px);
    font-weight: 400;
    line-height: var(--_16px);
    text-align: center;
    color: var(--words-green);
}

.card {
    display: flex;
    flex-direction: column;
    gap: var(--_8px);
}

.card > div {
    margin-bottom: var(--_12px);
}

.wordInfo {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_20px);
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.infoLabel {
    text-transform: lowercase;
    color: var(--words-green);
    width: fit-content;
}

.translation {
    color: var(--text-light-gray-secondary);
    margin-bottom: var(--_6px);
}

.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: var(--_12px);
    width: 50%;
    position: relative;
}

.infoText,
.exampleText {
    font-size: var(--_15px);
    font-weight: 400;
    line-height: var(--_20px);
    text-align: left;
    width: fit-content;
    white-space: wrap;
}

.exampleText {
    margin-bottom: var(--_6px);
}

.exampleText:last-child {
    margin-bottom: var(--_32px);
}

.exampleText button,
.wordText button {
    display: inline-flex;
    width: var(--_24px);
    height: var(--_24px);
    min-width: var(--_24px);
    margin-right: var(--_6px);
    margin-top: var(--_3px);
    padding: unset;
}

.wordText button {
    position: absolute;
    left: var(--_20px);
    top: var(--_20px);
    margin: 0;
}

.soundIcon path {
    stroke: var(--black);
}

.wordCard svg {
    width: var(--_14px);
    height: var(--_12px);
}

.buttons {
    display: flex;
    gap: var(--_10px);
    flex-wrap: wrap;
}

.buttons button:last-child {
    margin-left: auto;
}

.horizontalCont {
    display: flex;
    gap: var(--_6px);
    flex-wrap: wrap;
}

.wordLoader {
    opacity: 0.5;
    max-height: var(--_40px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    position: absolute;
}

.wordsWrapper {
    gap: var(--_12px);
    padding-bottom: var(--_20px);
    max-width: 100%;
}

.wordsWrapper.modal {
    padding: var(--_20px);
}

.wordsContainer {
    position: relative;
}

.learnWordsWrapper {
    padding-bottom: 0;
}

.learnWordsWrapper > div {
    background: var(--white);
}

.chaptersContainer {
    display: flex;
    flex-direction: column;
    gap: var(--_16px);
    height: 100%;
    position: relative;
    overflow: scroll;
}

.modesContainer {
    position: absolute;
    bottom: var(--_20px);
    transform: translate(-50%, -100%);
    left: 50%;
    width: calc(100% - 20px);
    background: var(--primary-light-gray);
    padding: var(--_4px);
    height: var(--_42px);
    border-radius: var(--_10px);
    box-shadow: 0px 3px 4px 0px var(--black-transparent);
}

.modeToggle,
.modeToggle > * {
    font-size: var(--_15px);
}

.learnButton {
    width: 100%;
    font-size: var(--_15px);
}

.addWordButton,
.settingsButton {
    cursor: pointer;
    width: var(--_40px);
    min-width: var(--_40px);
    height: var(--_40px);
    border-radius: var(--_8px);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.addWordButton {
    height: var(--_32px);
    width: var(--_32px);
    min-width: var(--_32px);
}

.settingsButton {
    position: absolute;
    top: var(--_12px);
    right: var(--_12px);
}

.addWordButton svg,
.settingsButton svg {
    width: var(--_18px);
    height: var(--_18px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.addWordButton path {
    fill: var(--black);
}

.settingsModal {
    padding: var(--_24px);
    background: var(--white);
    border-radius: var(--_16px);
    position: relative;
    min-width: 250px;
    max-width: 1090px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: var(--_24px);
    max-height: 95vh;
    overflow: scroll;
}

.settingsTitle {
    font-size: var(--_18px);
    font-weight: 500;
    margin-bottom: calc(-1 * var(--_8px));
}

.settingsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--_12px) var(--_16px);
}

.switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--primary-light-gray);
    border-radius: var(--_12px);
    height: var(--_64px);
    padding: var(--_16px);
    box-sizing: border-box;
    min-width: 25vw;
}

.label,
.toggleLabel {
    font-size: var(--_14px);
    color: var(--black);
    text-align: left;
    width: 100%;
}

.toggleLabel {
    margin-bottom: var(--_20px);
}

.packSizeSelector {
    width: 100%;
    padding: var(--_4px);
    height: var(--_42px);
    border-radius: var(--_10px);
    background: var(--primary-light-gray);
}

.emptyAlert {
    margin: auto;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.learnWords {
    width: 100%;
    position: relative;
    border: 1px solid var(--border-light-gray);
    border-radius: var(--_12px);
    overflow: hidden;
}

.learnWords::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
}

.learnWords.continue::before {
    background: var(--green-transparent);
}

.learnWords.repeat::before {
    background: var(--primary-transparent-red);
}

@media (hover: hover) {
    .word:hover {
        background: var(--light-gray);
    }
    .close:hover {
        opacity: 0.9;
    }
}
