@import-normalize;

@font-face {
    font-family: 'Taler';
    src: url('../assets/fonts/taler_medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: var(--black);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}

:root {
    --black: #000000;
    --black-hover: #1c1c1c;
    --black-transparent: #0000001a;
    --black-transparent-secondary: #00000072;

    --white: #ffffff;
    --white-pressed: #f6f6fa;
    --white-hover: #f8f8fa;
    --white-transparent: #ffffffc2;
    --light-gray: #fafaff;
    --night-gray: #2c2c2e;
    --dark-gray: #333333;
    --medium-gray: #3f4444;

    --purple: #b770ff;
    --purple-transparent: #b770ff76;
    --purple-hover: #ae6af2;
    --purple-pressed: #9c5fd9;
    --blue: #2f83ea;
    --blue-hover: #3688ed;
    --blue-pressed: #2c7fe3;
    --blue-transparent: #2f83ea1a;
    --light-purple: #b770ff1a;
    --light-purple-hover: #b770ff2b;
    --light-purple-pressed: #b770ff2f;
    --green: #34c759;
    --green-transparent: #34c7591f;
    --green-transparent-hover: #34c75938;
    --light-green: #f1fffa;
    --orange: #ffaa5d;
    --orange-hover: #ffaf64;
    --orange-pressed: #ed9e54;
    --orange-transparent: #ffaa5d1a;
    --orange-transparent-pressed: #efa05732;
    --orange-transparent-hover: #eb9e5529;
    --orange-light: #ffab5db5;

    --primary-light-gray: #f2f2f7;
    --primary-light-gray-hover: #e6e9f0;
    --primary-light-gray-pressed: #dfe3eb;
    --secondary-light-gray: #eeeeee;
    --transparent-gray: #ebebf54d;
    --border-light-gray: #d9d9d9;

    --text-light-gray: #3c3c434d;
    --text-light-gray-secondary: #3c3c4399;
    --text-disabled: #a8a8a8;
    --text-light-green: #34c75973;
    --text-light-red: #ff3a3075;

    --primary-red: #ff3b30;
    --primary-transparent-red: #ff3b3014;
    --primary-green: #34c759;
    --primary-transparent-green: #34c7591f;

    --dialogue-accent-purple: #5856d6;
    --dialogue-accent-purple-hover: #4644b9;

    --dialogue-accent-gray: #d8dce6;
    --dialogue-progress-purple: #b770ff66;

    --library-theme-background: #cdeaff3d;
    --library-theme-border: #0a10ff4a;

    --words-green: #42dfa7;
    --words-green-hover: #49e2ae;
    --words-green-pressed: #3acc96;
    --words-green-transparent: #42dfa780;
    --words-green-transparent-hover: #42dfa71a;

    --modal-overlay-background: #44444478;
    --onboarding-bubble-background: #bababad5;

    --selection-color: #e8e4ff;
    --accent-orange: #ff9f0a;
    --accent-orange-transparent: #ff9f0ab0;

    --unfinished-status-color: #fbf7ff;

    --yellow: #ffd60a;
    --warning-yellow: #fff7ee;

    --pdf-burgundy-transparent: #a425161a;
    --pdf-burgundy: #a42516;
    --pdf-burgundy-pressed: #911a0d;
    --pdf-burgundy-hover: #b32718;

    --hyperlink-blue-transparent: #2d5ad011;
    --hyperlink-blue: #7366bd;
    --landing-transparent-shadow: #32316d1a;

    --assets-fill-gray: #c8c8d0;

    --adptv: clamp(10px, calc(1vw + 1vh), 24px);
}

body {
    height: 100dvh;
    overscroll-behavior: none;
    overflow-x: hidden;
    overflow-y: scroll;
}

div {
    white-space: normal;
}

hr {
    outline: none;
    border: none;
}

h1 {
    font-size: 30px;
}

h1,
h2,
h3,
h4,
h5 {
    white-space: pre-wrap;
}

label {
    display: block;
}

button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

a {
    text-decoration: none;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    width: 100vw;
    background: var(--white);
}

::selection,
::-moz-selection {
    background: var(--selection-color);
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.react-pdf__Page__textContent textLayer {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.selectedSpan {
    background: var(--purple);
    color: var(--white);
    position: relative;
}

.selectedSpan::after {
    position: absolute;
    width: 5px;
    height: 100%;
    content: '';
    background: var(--purple);
}

.selectedSpan.lastSelected::after {
    display: none;
}
