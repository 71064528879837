.toast {
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: 300px;
    max-width: 450px;
    min-height: 64px;
    padding: 16px 48px 16px 32px;
    background-color: var(--white);
    color: var(--white);
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    z-index: 1000;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
}

.toast::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 16px;
    background: var(--white);
    border-radius: 6px 0 0 6px;
}

.toastContent {
    display: flex;
    align-items: center;
    gap: 16px;
}

.successIcon,
.warningIcon {
    min-width: 22px;
    min-height: 22px;
}

.successIcon path,
.errorIcon path {
    stroke: var(--white);
    stroke-width: 2px;
}

.iconContainer {
    width: 32px;
    min-width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 50%;
    background: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconContainer svg {
    min-height: 20px;
    max-height: 20px;
}

.iconContainer .infoIcon {
    min-height: unset;
    max-height: 17px;
}

.toast.success::before,
.toast.success .iconContainer {
    background-color: var(--green);
}

.toast.error::before,
.toast.error .iconContainer {
    background-color: var(--primary-red);
}

.toast.info::before,
.toast.info .iconContainer {
    background-color: var(--blue);
}

.toast.warning::before,
.toast.warning .iconContainer {
    background-color: var(--orange);
}

.toastText {
    font-size: 14px;
    color: var(--text-light-gray-secondary);
}
