.toggleButtonCont {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 32px;
}

.sectionTitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: capitalize;
}

.toggleButtonCont span {
    font-weight: 400;
}

.optionsContainer {
    display: flex;
    justify-content: center;
    width: min-content;
    height: 100%;
    gap: 4px;
}

.optionsContainer.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}

.option {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 32px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
    user-select: none;
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    padding: 0 6px;
    text-align: center;
    background: var(--white);
    border: 1px solid transparent;
    color: var(--text-light-gray-secondary);
}

.option.round {
    width: 30px;
}

.option.transparent {
    padding: 6px 20px;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    background: transparent;
    border: 1px solid transparent;
    color: var(--black);
}

.option.disabled {
    color: var(--text-disabled);
    background: var(--white);
    cursor: not-allowed;
}

.option.transparent.disabled {
    color: var(--text-disabled);
    background: var(--white);
    border-color: var(--secondary-light-gray);
    cursor: not-allowed;
}

.toggleButtonCont.filled,
.filled,
.wrong,
.correct,
.exercise {
    background: var(--primary-light-gray);
    border-radius: 8px;
}

.option.outlined {
    background: var(--primary-light-gray);
}

.option.error {
    background: var(--primary-light-gray);
    border: 1px solid var(--primary-red);
}

.option.active {
    color: var(--white);
    background: var(--night-gray);
}

.option.exercise.active {
    border: 1px solid var(--black);
    color: var(--black);
    background: var(--primary-light-gray-pressed);
}

.option.transparent.active {
    background: var(--white);
    border-color: var(--white);
    color: var(--black);
}

.option.pressed:not([disabled]):not(.disabled) {
    transition: none;
    background: var(--white-pressed);
}

.option.outlined.pressed:not([disabled]):not(.disabled) {
    background: var(--primary-light-gray-pressed);
}

.option.exercise.pressed:not([disabled]):not(.disabled) {
    background: var(--primary-light-gray-pressed);
}

.option.error.pressed:not([disabled]):not(.disabled) {
    background: var(--primary-light-gray-pressed);
}

.option.transparent.pressed:not([disabled]):not(.disabled) {
    background: var(--primary-light-gray-pressed);
    border-color: var(--primary-light-gray-pressed);
}

.option.active.pressed:not([disabled]):not(.disabled) {
    background: var(--black);
}

.option.active.transparent.pressed:not([disabled]) {
    background: var(--white-pressed);
    border-color: var(--white-pressed);
}

.option.active.exercise.pressed:not([disabled]):not(.disabled) {
    background: var(--primary-light-gray-pressed);
}

.option svg {
    margin-right: 4px;
}

.option.disabled path {
    stroke-opacity: 0.35;
    stroke: var(--night-gray);
}

.correct,
.wrong {
    pointer-events: none;
}

.correct .option.active {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
}

.wrong .option.active {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
}

@media (hover: hover) {
    .option:not(.disabled):not(.unavailable):not(.pressed):not(
            .exercise
        ):hover {
        background: var(--white-hover);
        transition: all 0.2s;
    }
    .option.error:not(.disabled):not(.unavailable):not(.pressed):hover {
        background: var(--primary-light-gray-hover);
        transition: all 0.2s;
    }
    .option.outlined:not(.disabled):not(.unavailable):not(.pressed):hover {
        background: var(--primary-light-gray-hover);
        transition: all 0.2s;
    }
    .option.exercise:not(.disabled):not(.unavailable):not(.pressed):hover {
        background: var(--primary-light-gray-hover);
        transition: all 0.2s;
    }
    .option.transparent:not(.disabled):not(.unavailable):not(.pressed):hover {
        background: var(--primary-light-gray-hover);
        border-color: var(--primary-light-gray-hover);
        color: var(--black);
        transition: all 0.2s;
    }
    .option.active:not(.disabled):not(.unavailable):not(.pressed):not(
            .exercise
        ):hover {
        background: var(--black-hover);
        color: var(--white);
        transition: all 0.2s;
    }
    .option.transparent.active:not(.disabled):not(.unavailable):not(
            .pressed
        ):hover {
        background: var(--white-hover);
        border-color: var(--white-hover);
        color: var(--black);
        transition: all 0.2s;
    }
}
