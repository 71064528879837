.questions {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
}

.question {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray);
}

.userText {
    font-size: 14px;
    font-style: italic;
    padding: 10px;
    border-radius: 12px;
    background-color: var(--transparent-gray);
    border: 1px solid var(--border-light-gray);
}

.answer,
.textAnswer {
    width: 100%;
    font-weight: 400;
    font-size: 12px;
    padding: 6px 32px 8px 8px;
    margin: 0;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    box-sizing: border-box;
    min-height: 39px;
    line-height: 23px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    outline: none;
    overflow-y: hidden;
    height: max-content;
    transition: background-color 0.1s;
    resize: none;
    font-family: Noto Sans;
}

.answer::placeholder,
.textAnswer::placeholder {
    color: var(--text-light-gray-secondary);
    font-weight: 400;
}

.textAnswer {
    min-height: 300px;
}

.pdfGap {
    width: 100%;
    box-sizing: border-box;
    height: 24px;
    padding: 0 9px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    border: 1px solid transparent;
}
