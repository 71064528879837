.keyboardGap {
    cursor: pointer;
    min-width: 50px;
    max-width: 400px;
    height: 21px;
    margin: 0;
    padding: 0px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    outline: none;
    font-weight: 400;
    line-height: 17px;
    transition: width 0.2s;
}

.keyboardGap.pdf {
    min-width: 100px;
    height: 24px;
    pointer-events: none;
}

.keyboardGap.correct,
.keyboardGap.wrong {
    cursor: default;
    color: var(--white);
}

.keyboardGap.correct {
    background-color: var(--primary-green);
}

.keyboardGap.wrong {
    background-color: var(--primary-red);
}

.keyboardGap.active:not(:focus) {
    border: 1px solid var(--black);
    color: var(--black);
    background: var(--primary-light-gray-pressed);
}

.widthCalculator,
.widthActiveCalculator {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: hidden;
    min-width: 30px;
    font-weight: 400;
    line-height: 17px;
    white-space: pre;
    line-break: strict;
}

.widthActiveCalculator {
    border: 1px solid var(--black);
}
