.modal > div {
    overflow: hidden;
}

.word {
    font-size: var(--_24px);
    font-weight: 700;
    line-height: 41px;
    margin-bottom: -8px;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
}

.packsList {
    display: flex;
    flex-direction: column;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    gap: 12px;
    flex-grow: 1;
    overflow: scroll;
}

.packsList > * {
    position: relative;
    overflow: visible;
}

.packsList > *::after {
    content: '';
    width: 100%;
    height: 0.3px;
    background: var(--border-light-gray);
    position: absolute;
    left: 0;
    bottom: -6px;
}
