.controlsCont {
    margin: 24px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.controlsCont button {
    position: relative;
}

.trackLine {
    width: calc(100% + 6px);
    height: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    margin-bottom: 0px;
    margin-left: -3px;
    background: var(--blue-transparent);
    border-radius: 3px;
}

.trackLine::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 9px;
    left: 0;
    background-color: transparent;
    top: -3px;
    z-index: 0;
}

.trackLine::after {
    content: '';
    position: absolute;
    height: 4px;
    background: var(--blue-transparent);
    pointer-events: none;
    z-index: 1;
}

.trackLineWrapper {
    position: relative;
}

.playPauseBtnsCont {
    height: calc(100% - 3px);
    position: relative;
    display: flex;
    justify-content: center;
    gap: 48px;
    padding: 0 20px;
    align-items: center;
    transition: filter 0.2s;
}

.playPauseBtnsCont.disabled {
    filter: grayscale(1) brightness(0.2);
    pointer-events: none;
}

.playPauseBtn {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 12;
}

.dragger {
    position: absolute;
    top: 50%;
    transition: top 0.3s;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    background: var(--blue);
    pointer-events: none;
    z-index: 6;
}

.timeItem {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    position: absolute;
    top: 10px;
    color: var(--black);
}

.timeItem:first-child {
    left: 0;
}

.timeItem:last-of-type {
    right: 0;
}

.trackProgress {
    background: var(--blue);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: 3;
    border-radius: 3px;
}

.button {
    background: none;
    border: none;
    cursor: pointer;
}

.button * {
    transition: all 0.2s;
}

.button svg {
    width: 35px;
    height: 35px;
}

.button path {
    fill: var(--black);
    stroke: var(--black);
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
    gap: 12px;
    position: relative;
}

.loaderContainer .loader {
    position: static;
    transform: none;
    opacity: 0.5;
}

.loaderText {
    color: var(--text-light-gray-secondary);
    font-size: 14px;
    line-height: 20px;
}
