.theme {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: start;
    justify-content: space-between;
    min-width: calc(25% - 9px);
    max-width: calc(25% - 9px);
    margin-right: 12px;
    max-height: 100%;
    padding: 10px 0;
    vertical-align: top;
    white-space: normal;
    scroll-margin: 8px;
    user-select: none;
    background: var(--light-gray);
    border: 1px solid var(--primary-light-gray);
    border-radius: 12px;
}

.theme.library {
    background: var(--library-theme-background);
    border-color: var(--library-theme-background);
}

.lessons {
    padding: 8px 16px;
    overflow: auto;
}

.lessons::-webkit-scrollbar {
    width: 0;
}

.lessons.locked {
    pointer-events: none;
}

.lessons > div:last-child > div {
    margin-bottom: 0;
}

.title,
.theme > input {
    padding: 0px 8px 0px 16px;
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    background: transparent;
    border: transparent;
    margin-right: 30px;
    min-height: 24px;
    max-height: 24px;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.actions {
    position: absolute;
    right: 16px;
    top: 8px;
    display: flex;
    gap: 2px;
    padding: 4px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;
}

@media (hover: hover) {
    .theme:hover .actions {
        opacity: 1;
    }
}
