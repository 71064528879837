.emptyAlert {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emptyAlert.outlined {
    border-radius: 12px;
    border: 1px solid var(--border-light-gray);
}

.iconEyes {
    max-width: 100px;
    max-height: 100px;
}

.iconEyes path {
    fill: var(--assets-fill-gray);
}

.dictionaryIcon,
.videoIcon,
.exerciseIcon,
.situationIcon,
.todoIcon {
    height: 85px;
    width: 85px;
    border-radius: 16px;
    background: var(--primary-light-gray);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dictionaryIcon svg,
.videoIcon svg,
.todoIcon svg {
    width: 40px;
    height: 40px;
}

.videoIcon svg * {
    stroke: var(--assets-fill-gray);
}

.videoIcon svg path:last-child {
    fill: transparent;
}

.todoIcon path {
    stroke: var(--assets-fill-gray);
}

.exerciseIcon svg {
    width: 40px;
    height: 40px;
}

.exerciseIcon svg * {
    stroke: var(--assets-fill-gray);
}

.situationIcon svg {
    width: 40px;
    height: 40px;
}

.situationIcon svg * {
    stroke: var(--assets-fill-gray);
}

.alertTitle,
.alertSubtitle {
    font-family: Noto Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.alertSubtitle {
    font-size: 16px;
    line-height: 18.8px;
}
