.modeContainer {
    flex-grow: 1;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.card {
    height: 90%;
    border-radius: 28px;
    box-shadow: 0px 10px 60px 0px var(--black-transparent);
    width: fit-content;
    min-width: 450px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2px;
}

.cardInner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 26px;
    padding: 16px;
    background: var(--white);
    user-select: none;
    cursor: grab;
    z-index: 1;
}

.card * {
    font-weight: 300 !important;
}

.iconMe,
.iconThey {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;
    margin: 0 auto;
    padding: 2px 6px;
    border-radius: 16px;
}

.iconMe span,
.iconThey span {
    font-size: 12px;
}

.iconThey {
    background: var(--primary-light-gray);
}

.iconThey path,
.iconThey span {
    color: var(--black);
    stroke: var(--black);
}

.iconMe {
    background: var(--dialogue-accent-purple);
}

.iconMe path,
.iconMe span {
    color: var(--white);
    stroke: var(--white);
}

.equalizer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5px;
}

.equalizer span {
    content: '';
    width: 1.5px;
    height: 12px;
    border-radius: 12px;
}

.iconMe .equalizer span {
    background: var(--white);
}

.iconThey .equalizer span {
    background: var(--black);
}

.equalizer.active span {
    animation: equalize 1s infinite ease-in-out;
}

.equalizer span:nth-child(4) {
    animation-delay: 0s;
}
.equalizer span:nth-child(3) {
    animation-delay: 0.2s;
}
.equalizer span:nth-child(5) {
    animation-delay: 0.4s;
}
.equalizer span:nth-child(2) {
    animation-delay: 0.6s;
}
.equalizer span:nth-child(1) {
    animation-delay: 0.8s;
}

.progressContainer {
    position: absolute;
    top: 2.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    min-width: 430px;
    max-width: calc(50% - 20px);
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    z-index: 1;
}

.progressChip {
    flex-grow: 1;
    height: 3px;
    border-radius: 3px;
    background: var(--text-disabled);
}

.progressChip.active {
    background: var(--orange);
}

@keyframes equalize {
    0%,
    100% {
        height: 6px;
    }
    50% {
        height: 12px;
    }
}
