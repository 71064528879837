.chapterContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.chapterTypeCont {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    margin-top: -1px;
    border-top: 1px solid var(--border-light-gray);
    cursor: pointer;
}

.chapterTypeCont svg {
    min-width: 20px;
}

.chapterTypeCont.words {
    background-image: url('../../../../assets/svg/wizard-words-background.svg');
}

.chapterTypeCont.track {
    background-image: url('../../../../assets/svg/wizard-track-background.svg');
}

.chapterTypeCont.exercise {
    background-image: url('../../../../assets/svg/wizard-exercise-background.svg');
}

.chapterTypeCont.situation {
    background-image: url('../../../../assets/svg/wizard-situation-background.svg');
}

.chapterType {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.control {
    margin-left: auto;
    min-width: 20px;
    padding: 6px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.1s;
    user-select: none;
}

.control > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.files {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.iconArrow path {
    stroke: var(--black);
    stroke-opacity: 1;
}

.chaptersList {
    overflow: hidden;
}

.chaptersList.hidden {
    max-height: 0;
}
