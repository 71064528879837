.exercisesWrapper {
    gap: 12px;
    padding-bottom: 20px;
    max-width: 100%;
}

.exercisesWrapper .toolbarInput {
    width: 50%;
    min-width: 50%;
}
