.emptyAlert {
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 80%;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.iconEyes path {
    fill: var(--assets-fill-gray);
}

.alertTitle,
.alertSubtitle {
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.alertTitle {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
}

.alertSubtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
