.interactionBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    margin: var(--_150px) auto;
    position: relative;
    padding: 0 var(--_24px);
    width: 100%;
}

.textContainer {
    width: 100%;
}

.cardBlock {
    display: flex;
    justify-content: center;
    gap: var(--_16px);
    max-width: 1680px;
}

.mainTitle {
    max-width: 50vw;
    font-size: var(--_36px);
    font-weight: 500;
    line-height: var(--_42px);
    margin-bottom: var(--_12px);
}

.subtitle,
.subtitle span {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: #1c1c1e80;
    max-width: 60%;
    margin-bottom: var(--_40px);
}

.subtitle span {
    font-weight: 500;
    color: var(--black);
}

.logoFR {
    width: 18vw;
    height: 18vw;
    top: -7%;
    left: -2%;
    z-index: -1;
    transform: rotate(-45deg);
}

.cardWrapper {
    width: 100%;
}
