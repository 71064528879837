.theoryCard {
    padding: 40px 20px;
    overflow-y: scroll;
    height: 100%;
    position: relative;
}

.theoryCard::after {
    position: sticky;
    display: block;
    bottom: 0;
    height: 50px;
    transform: translateY(100%);
    width: 100%;
    content: '';
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        var(--white)
    );
}

.theoryCard::-webkit-scrollbar {
    width: 0;
}

.mainInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mainInfo {
    gap: 8px;
}

.wordText {
    font-size: var(--_24px);
    font-weight: 700;
    line-height: 41px;
    margin-bottom: -8px;
    width: 100%;
    text-align: center;
    word-wrap: break-word;
}

.wordInfo {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: var(--text-light-gray-secondary);
}

.additionalInfo,
.infoCont,
.examplesCont {
    display: flex;
    flex-direction: column;
}

.additionalInfo {
    gap: 12px;
    width: 100%;
}

.examplesCont {
    gap: 6px;
}

.partOfSpeech {
    margin-top: 32px;
    margin-bottom: 16px;
    color: var(--words-green);
    font-size: 15px;
    text-align: center;
}

.infoLabel {
    text-transform: lowercase;
    color: var(--words-green);
    width: fit-content;
}

.infoText,
.infoTranslation {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.infoTranslation {
    color: var(--text-light-gray-secondary);
}

.playButton {
    height: 56px;
    width: 56px;
    padding: 0;
    border-radius: 50%;
    position: relative;
}

.playButton svg {
    height: 16px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.playButton path {
    stroke: var(--black);
}

.buttonContainer button {
    display: inline-flex;
    width: var(--_24px);
    height: var(--_24px);
    min-width: var(--_24px);
    margin-right: var(--_6px);
    margin-top: var(--_3px);
    padding: unset;
    position: absolute;
    left: 16px;
    top: 16px;
    margin: 0;
    z-index: 4;
}

.soundIcon {
    width: var(--_14px);
    height: var(--_12px);
}

.soundIcon path {
    stroke: var(--black);
}

.alert {
    margin: auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 12px;
}

.alert .alertLoader {
    transform: scale(0.5);
    position: static;
    opacity: 0.5;
}

.alert .alertLoader > div {
    transform: none;
    top: unset;
    left: unset;
}

.alert p {
    opacity: 0.5;
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.alert button {
    margin-top: 12px;
}
