div.profileWrapper {
    overflow-y: auto;
    position: relative;
    background: var(--primary-light-gray);
    padding: 12px 0;
    max-width: 100vw;
}

.profile {
    background: var(--white);
    max-width: 1040px;
    max-height: 695px;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0px;
    margin: auto;
    padding: 0 24px;
    border-radius: 24px;
    position: relative;
}

.aside {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px 24px 24px 0;
    border-right: 2px solid var(--primary-light-gray);
}

.aside .button {
    width: 100%;
    padding: 10px 12px;
}

.aside .button:nth-child(4) {
    margin-top: auto;
}

.viewBlock {
    padding: 24px 18px 24px 42px;
    width: 100%;
    overflow: scroll;
}

.viewTitle {
    font-family: Noto Sans;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}

.viewInner {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    gap: 24px;
    width: 100%;
}

.inputCont > p,
.levelCont > p {
    color: var(--text-light-gray-secondary);
}

.viewInner > div {
    width: 100%;
}

.viewInner .select {
    width: 100%;
}

.horizontalCont {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.horizontalCont .inputCont {
    flex-grow: 1;
}

.logoutIcon path {
    fill: var(--primary-red);
}

.profile .onboardingButton {
    margin: auto;
}

.teachersList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.teacherCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-light-gray);
    border-radius: 12px;
    padding: 12px 24px;
}

.teacherCard svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.teacherCard path {
    stroke: var(--primary-red);
}

.emptyAlert {
    color: var(--text-light-gray-secondary);
}
