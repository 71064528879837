.editModal {
    padding: 0 0 12px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    align-items: center;
    min-width: 800px;
    max-width: 80vw;
    min-height: 50vh;
    max-height: 95vh;
    height: fit-content;
    box-sizing: border-box;
    position: absolute;
    background: var(--white);
    border-radius: 16px;
    overflow: auto;
}

.titleContainer {
    width: 100%;
    height: 53.2px;
    min-height: 53.2px;
    padding: 14px 24px;
    position: relative;
    overflow: hidden;
}

.titleContainer .title {
    width: fit-content;
}

.backgroundCircle {
    width: 282px;
    height: 282px;
    border-radius: 50%;
    background: #2f48ea;
    position: absolute;
    filter: blur(100px);
    z-index: 1;
    pointer-events: none;
}

.backgroundCircle:nth-child(1) {
    opacity: 0.75;
    top: -40px;
    right: -20px;
}

.backgroundCircle:nth-child(2) {
    opacity: 0.45;
    left: -20px;
}

.titleContainer .title {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    z-index: 2;
}

.descriptionInput {
    width: 100%;
    font-weight: 400;
    padding: 6px 32px 0px 8px;
    margin: 0;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    box-sizing: border-box;
    line-height: 18px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    outline: none;
    overflow-y: hidden;
    height: max-content;
    transition: background-color 0.1s;
    resize: none;
    font-size: 12px;
    min-height: 32px;
}

.editModal > * {
    width: 100%;
}

.buttonsContainer {
    margin-top: auto;
    background: var(--white);
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 12px 24px 0px;
}

.editorContainer {
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    padding: 10px 24px;
    gap: 20px;
}

.horizontalCont {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    justify-content: space-between;
}
