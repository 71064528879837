.AppWrapper {
    width: 100%;
    height: 100dvh;
    max-height: -webkit-fill-available;
    max-width: 100vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow: hidden;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    display: flex;
    flex-direction: column;
}

.AppWrapper::-webkit-scrollbar {
    z-index: 9999;
    width: 0;
}

.AppWrapper.teacher * {
    font-family: Noto Sans !important;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
