.switch {
    height: 0;
    width: 0;
    visibility: hidden;
}

.label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 56px;
    min-width: 56px;
    height: 32px;
    background: var(--border-light-gray);
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
}

.label.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.label .btn {
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    transition: 0.2s;
    background: var(--white);
}

.switch:checked + .label .btn {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.label.active {
    background: var(--purple);
}

.label.active .btn {
    background: var(--white);
}

.label.track.active {
    background: var(--purple);
}

.label.words.active {
    background: var(--words-green);
}

.label.situation.active {
    background: var(--orange);
}

.label.exercise.active {
    background: var(--blue);
}
