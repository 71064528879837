.situationParamsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    height: 90%;
    background: var(--white);
    box-shadow: 0px 10px 60px 0px var(--black-transparent);
    border-radius: 28px;
    padding: 24px 16px 64px;
    width: fit-content;
    min-width: 450px;
    max-width: 50%;
    user-select: none;
    position: relative;
}

.title {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-right: 20px;
}

.situationParams {
    display: flex;
    gap: 4px;
}

.situationParams p {
    font-size: 12px;
    color: var(--text-light-gray-secondary);
    text-transform: uppercase;
}

.situationParams p::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.situationParams p:last-child::after {
    display: none;
}

.modesContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.modesList,
.settingsContainer {
    width: 50%;
    min-width: 420px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.settingsContainer {
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 12px;
    padding: 36px 24px;
    box-shadow: 0px 0px 8px 4px var(--black-transparent);
    background: var(--white);
}

.settingsContainer.hidden {
    opacity: 0;
    pointer-events: none;
}

.modesContainer p,
.settingsContainer p {
    text-align: left;
    font-size: 12px;
}

.settingsButton {
    position: absolute;
    top: 24px;
    right: 16px;
}

.modesContainer .modesList > button {
    width: 100%;
    height: 42px;
    min-height: 42px;
    justify-content: space-between;
    flex-direction: row-reverse;
    border: none;
}

.completeButton {
    width: 100%;
    height: 42px;
    min-height: 42px;
    position: relative;
}

.completeButton::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--text-light-gray-secondary);
    left: 0;
    right: 0;
    top: -16px;
}

.completeButton.hidden {
    opacity: 0;
    pointer-events: none;
}

.modeArrow {
    transform: rotate(90deg);
}

.modeArrow path {
    stroke: var(--black);
    stroke-opacity: 1;
}

.settingsInner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}

.settingsInner.hidden {
    opacity: 0;
    pointer-events: none;
}

.settingsInner > div {
    flex-grow: 1;
}

.toneContainer {
    display: flex;
    height: 32px;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
}

.happy,
.neutral,
.sad {
    min-width: 16px;
    min-height: 16px;
}

.happy path,
.neutral path,
.sad path {
    stroke: var(--black);
}

@media (max-height: 768px) {
    .modesList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}
