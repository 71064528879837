.sentencesCont {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding-bottom: 100px;
}

.sentence {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.text,
.variantsCont {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    user-select: none;
    min-height: 19px;
}

.variantsCont.pdf {
    pointer-events: none;
}

.text {
    gap: 3px 3px;
    min-height: 16px;
    position: relative;
}

.text:not(.done)::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--text-light-gray-secondary);
}

.text.done {
    margin-bottom: -25px;
}

.answer,
.variant {
    cursor: pointer;
    box-sizing: border-box;
    height: 20px;
    line-height: 18px;
    min-width: 30px;
    padding: 0 9px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    transition: all 0.2s;
    border: 1px solid var(--primary-light-gray);
}

.answer.active,
.answer.correct,
.answer.wrong {
    padding: 0;
    border: none;
    background: transparent;
    min-width: unset;
    margin-right: 1.5px;
}

.answer.correct {
    color: var(--green);
}

.answer.wrong {
    text-decoration: underline;
    /* color:  var(--primary-red); */
    text-decoration-color: var(--primary-red);
}

.pdfGap {
    width: 100%;
    box-sizing: border-box;
    height: 24px;
    padding: 0 9px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    border: 1px solid transparent;
}

@media (hover: hover) {
    .variant:hover {
        background-color: var(--border-light-gray);
    }
}

@media print {
    .sentencesCont,
    .sentence,
    .variantsCont {
        break-inside: avoid !important;
    }
}
