.controlsCont {
    width: 100%;
    padding: 0 14px;
    height: 50px;
    position: absolute;
    bottom: -2px;
    background: linear-gradient(
        360deg,
        var(--black) 0%,
        var(--black-transparent) 100%
    );
    z-index: 2;
}

.controlsCont::after {
    position: absolute;
    display: block;
    bottom: 0;
    left: -10px;
    height: 200px;
    transform: translateY(0%);
    width: calc(100% + 10px);
    content: '';
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        var(--black)
    );
    z-index: -1;
    pointer-events: none;
}

.controlsCont button {
    position: relative;
}

.trackLine {
    width: calc(100% + 6px);
    height: 3px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    margin-bottom: 0px;
    margin-left: -3px;
    background: var(--transparent-gray);
}

.trackLine::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 9px;
    left: 0;
    background-color: transparent;
    top: -3px;
    z-index: 0;
}

.trackLine::after {
    content: '';
    position: absolute;
    height: 3px;
    background: var(--transparent-gray);
    pointer-events: none;
    z-index: 1;
}

.trackLineWrapper {
    position: relative;
}

.buttonsContainer {
    height: calc(100% - 3px);
    position: relative;
    display: flex;
    justify-content: center;
    gap: 48px;
    padding: 0 20px;
    align-items: center;
    transition: filter 0.2s;
}

.buttonsContainer.disabled {
    filter: grayscale(1) brightness(0.2);
    pointer-events: none;
}

.playPauseBtn {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 12;
}

.dragger {
    position: absolute;
    top: 50%;
    transition: top 0.3s;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 13px;
    background: var(--purple);
    pointer-events: none;
    z-index: 6;
}

.timeItem {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    position: absolute;
    top: 10px;
    color: var(--white);
}

.timeItem:first-child {
    left: 0;
}

.timeItem:last-of-type {
    right: 0;
    color: var(--transparent-gray);
}

.trackProgress {
    background: var(--purple);
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    pointer-events: none;
    z-index: 3;
}

.basicControlButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    max-width: 30px;
    width: 30px;
}

.basicControlButton * {
    transition: all 0.2s;
}

.basicControlButton svg {
    width: 35px;
    height: 35px;
}

.basicControlButton.disabled {
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
}

.playbackBtn,
.autoPauseBtn {
    width: 24px;
    height: 24px;
    border-radius: 30%;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.playbackBtn.active,
.autoPauseBtn.active {
    background: var(--purple);
}

.playbackBtn {
    position: relative;
}

.playbackBtn span {
    font-size: 10px;
    font-weight: 700;
    color: var(--black);
}

.playbackBtn.active span {
    color: var(--white);
}

.autoPauseBtn svg {
    width: 14px;
    height: 14px;
}

.autoPauseBtn path {
    stroke: var(--black);
    fill: var(--black);
}

.autoPauseBtn.active path {
    stroke: var(--white);
    fill: var(--white);
}

.autoPauseBtn circle {
    stroke: var(--black);
    fill: var(--black);
}

.autoPauseBtn.active circle {
    stroke: var(--white);
    fill: var(--white);
}

.speedOptionsContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 37px;
    top: -32px;
    transform: translate(-50%, -100%);
    left: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
}

.speedOptionsContainer.visible {
    opacity: 1;
    pointer-events: all;
}

.speedBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 30%;
    cursor: pointer;
    background-color: var(--white);
    font-size: 10px;
    font-weight: 700;
}

.speedBtn.active {
    background-color: var(--purple);
    color: var(--white);
}
