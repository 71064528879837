.previewContainer {
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-light-gray);
    border-radius: 12px;
    overflow: hidden scroll;
    display: flex;
    flex-direction: column;
}

.wordCard {
    width: 100%;
    height: max-content;
    max-height: unset;
    position: static;
    border: none;
    box-shadow: none;
    overflow: visible;
    position: relative;
    top: 0;
    left: 0;
}

.wordPackPreview {
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    height: 100%;
    gap: 16px;
}
