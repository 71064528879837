.previewCont {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.answer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    user-select: none;
    font-weight: 400;
    white-space: wrap;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    padding: 6px 12px;
    background: var(--primary-light-gray);
    color: var(--text-light-gray-secondary);
    transition: all 0.2s;
    user-select: none;
    -webkit-user-drag: none;
    border: 1px solid transparent;
}

.answer.active {
    color: var(--white);
    background: var(--night-gray);
}

.answer.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
    pointer-events: none;
}

.answer.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
    pointer-events: none;
}

.answer.pdf {
    pointer-events: none;
    position: relative;
}

.answer.pdf::before {
    width: 12px;
    height: 12px;
    border: 1px solid var(--text-light-gray-secondary);
    border-radius: 50%;
    content: '';
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translate(-100%, -50%);
}

@media (hover: hover) {
    .answer:not(.correct):not(.wrong):hover {
        background-color: var(--secondary-light-gray);
    }
    .answer.active:hover {
        background: var(--black-hover);
        color: var(--white);
        transition: all 0.2s;
    }
}
