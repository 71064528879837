.doneAlertContainer {
    flex-grow: 1;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.doneCard {
    height: 90%;
    border-radius: 28px;
    box-shadow: 0px 10px 60px 0px var(--black-transparent);
    width: fit-content;
    min-width: 450px;
    max-width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.doneCard::before,
.doneCard::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
        90deg,
        var(--orange),
        var(--orange-light),
        var(--white),
        var(--orange),
        var(--orange-light),
        var(--white)
    );
    background-size: 400%;
    z-index: -1;
    animation: glow 10s linear infinite;
    width: 100%;
    border-radius: 28px;
    opacity: 0.5;
}

.doneCard::after {
    filter: blur(12px);
    transform: translate3d(0, 0, 0);
}

.doneCardInner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 26px;
    padding: 16px;
    background: var(--white);
    user-select: none;
    z-index: 1;
}

.doneCardInner p {
    text-align: center;
    font-size: 18px;
    line-height: 36px;
    white-space: pre-line;
}
.doneCardInner .subtitle {
    font-size: 14px;
    margin-top: 12px;
    line-height: 20px;
}

.starIcon {
    width: 100px;
    height: 100px;
    z-index: -1;
}

.starIcon path {
    fill: var(--orange);
    stroke: transparent;
    animation: starGlow 1s infinite alternate ease-in-out;
}
