.wordsWrapper {
    gap: 12px;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding-bottom: 16px;
    overflow: hidden;
}

.wordsInnerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    gap: 12px;
    padding-bottom: 16px;
    overflow: hidden;
}

.wordPacksList {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.wordPacksList > div:nth-child(1) {
    border-radius: 16px 16px 0 0;
    border-bottom: none;
}

.chaptersContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    position: relative;
    overflow: scroll;
}

.wordPacksList .chaptersContainer {
    height: unset;
    flex-grow: 1;
}

.wordPacksList .chaptersContainer > div {
    border-radius: 0 0 16px 16px;
    border-top: none;
}
