.wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    border-radius: 12px;
    overflow: hidden;
    user-select: none;
}

.wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    margin-left: -200%;
    height: 100%;
}

.controls {
    position: absolute;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    transition: opacity 0.2s;
    z-index: 3;
}

.player {
    pointer-events: none;
}

.overlay,
.firstOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
}

.firstOverlay {
    background: rgba(0, 0, 0, 0.35);
}

.fakePreview {
    width: 100%;
    height: 130%;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
    background: var(--black);
    pointer-events: none;
}

.firstPlayButton {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--purple);
    padding: 6px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstPlayButton svg {
    margin-left: 3px;
}

.imagefirstPlayButtonContainer img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

@media (hover: hover) {
    .wrapper:not(.idle):hover .controls {
        opacity: 1;
        pointer-events: all;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
