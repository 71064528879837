.chapterCard {
    min-height: 80px;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    transition: background-color 0.1s;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.chapterCard.active {
    background: var(--primary-light-gray);
}

.chapterCard.onboarding {
    transition: none !important;
}

.chapterCard.pending p {
    opacity: 0.4;
}

.chapterCard.words:not(.pack) {
    min-height: 52px;
    max-height: 52px;
    padding: 2px 12px;
    gap: 0;
}

.imageContainer {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    transition: margin 0.2s cubic-bezier(0.42, 0, 0.58, 1);
    position: relative;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0;
    animation: fadeIn 0.2s ease-in-out forwards;
}

.track .imageContainer {
    background: transparent;
}

.situation .imageContainer {
    background: transparent;
}

.exercise .imageContainer {
    background: transparent;
}

.words .imageContainer {
    background: transparent;
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    gap: 4px;
    position: relative;
}

.wordCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0;
}

.titleCont {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 14px;
    padding: 2px 6px;
}

.title,
.translation {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    white-space: pre-wrap;
    text-align: left;
    overflow: hidden;
}

.wordCard.invalid .title {
    color: var(--text-light-red);
}

.translation {
    padding: 0 6px;
    color: var(--black);
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0.5;
}

.infoContainer {
    display: flex;
    gap: 4px;
    justify-content: flex-start;
    align-items: center;
    min-height: 12px;
    max-height: 12px;
}

.cardInner button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 2;
}

.labelCont {
    display: flex;
    gap: 3px;
    align-items: center;
}

.labelCont,
.label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    white-space: nowrap;
}

.label::after {
    content: '∙';
    font-weight: 600;
    margin-left: 4px;
}

.label.exercise {
    color: var(--blue);
}

.label.words {
    color: var(--words-green);
}

.label.situation {
    color: var(--orange);
}

.label.track {
    color: var(--purple);
}

.label:last-child::after {
    display: none;
}

.modalityIcon svg {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.friendly path {
    fill: var(--primary-green);
}

.neutral path {
    fill: var(--yellow);
}

.rude path {
    fill: var(--primary-red);
}

.imageContainer .folder,
.imageContainer .count,
.imageContainer .greenFolder,
.imageContainer .whiteCount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.greenFolder path {
    fill: var(--words-green);
    stroke: var(--border-light-gray);
}

.imageContainer .count,
.imageContainer .whiteCount {
    top: calc(50% + 5px);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.imageContainer .count {
    color: var(--words-green);
}

.imageContainer .whiteCount {
    color: var(--white);
}

.progressContainer {
    width: 96px;
    min-height: 2px;
    display: flex;
    gap: 2px;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 6px;
    left: 18px;
    transition: margin 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}

.trackProgress {
    height: 2px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    bottom: 0;
    left: 0;
    flex-grow: 1;
    background: var(--purple);
}

.progressFill {
    height: 100%;
    right: 0;
    position: absolute;
    background-color: var(--secondary-light-gray);
}

.wordProgress {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
    width: 16px;
    height: 100%;
    padding: 6px 0;
}

.wordProgress > span {
    width: 100%;
    height: 2px;
    content: '';
    background: var(--text-light-gray);
    border-radius: 2px;
}

.wordProgress .done {
    background: var(--purple);
}

.slideButton {
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    right: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: right 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}

.slideButton svg {
    transform: scale(1.5);
}

.slideButton.red {
    background: var(--primary-red);
}

.slideButton.red svg path {
    stroke: var(--white);
}

.slideButton.green {
    background: var(--green);
}

.slideButton.green svg path {
    stroke: var(--white);
    stroke-opacity: 1;
}

.slideButton.disabled {
    filter: grayscale(1);
    cursor: not-allowed;
}

.status.unfinished,
.status.awaitingEvaluation,
.status.done {
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    padding: 1px 2px;
    border-radius: 4px;
    width: fit-content;
}

.status.unfinished {
    color: var(--purple);
    background: var(--white);
    border: 1px solid var(--purple-transparent);
}

.status.awaitingEvaluation {
    color: var(--white);
    background: var(--purple);
    border: 1px solid var(--purple-transparent);
}

.status.done {
    color: var(--white);
    background: var(--words-green);
    border: 1px solid var(--words-green-transparent);
}

.dot {
    width: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: var(--text-light-gray);
    text-transform: uppercase;
    font-weight: 600;
}

.translation .wordLoader {
    position: static;
    transform: none;
    left: unset;
    top: 20px;
    left: 6px;
}

.actionButtonsContainer,
.wordActionButtonsContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    top: 12px;
    right: 16px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    z-index: 1;
}

.wordActionButtonsContainer {
    gap: 0px;
    top: 0;
    right: 0;
    height: 100%;
}

.progressContainer {
    display: flex;
    gap: 3px;
    width: 95%;
    height: 3px;
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
}

.progressChip {
    flex-grow: 1;
    height: 3px;
    background: var(--border-light-gray);
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.progressFill {
    left: 0;
    height: 100%;
    z-index: 2;
    position: absolute;
}

.progressFill.situation {
    background: var(--orange);
}

.progressFill.exercise {
    background: var(--blue);
}

.progressFill.track {
    background: var(--purple);
}

@media (hover: hover) {
    .chapterCard:not(.onboarding):not(.active):hover {
        background: var(--white-hover);
    }

    .chapterCard:not(.onboarding).active:hover {
        background: var(--secondary-light-gray);
    }

    .chapterCard:not(.onboarding).active:hover .actionButtonsContainer {
        pointer-events: all;
        opacity: 1;
    }

    .chapterCard.active:hover .wordActionButtonsContainer {
        pointer-events: all;
        opacity: 1;
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
