* {
    color: var(--black-hover);
}

.card {
    background-color: var(--white-pressed);
    padding: var(--_20px);
    border-radius: var(--_12px);
    width: 100%;
    min-width: 25%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    z-index: -1;
    border-radius: var(--_12px);
}

.cardImg {
    margin-bottom: var(--_36px);
    width: var(--_72px);
    height: var(--_72px);
    padding: var(--_20px);
    background-color: var(--white);
    border-radius: var(--_12px);
    box-shadow: 0px 3.7px 24.88px 0px var(--landing-transparent-shadow);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardImg svg {
    width: var(--_36px);
    height: var(--_36px);
}

.title {
    font-size: var(--_22px);
    font-weight: 400;
    line-height: var(--_30px);
    letter-spacing: -0.01em;
    margin-bottom: var(--_12px);
}

.description {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.01em;
    color: #1c1c1e80;
    overflow-y: auto;
}

.pdf {
    background-color: var(--pdf-burgundy-transparent);
}

.hyperlinks {
    background-color: var(--hyperlink-blue-transparent);
}

.pony {
    background-color: var(--light-purple);
}

.button {
    margin: auto;
    margin-bottom: 0;
    width: 100%;
    border-radius: var(--_12px);
    padding: var(--_16px) var(--_20px);
    font-size: var(--_16px);
    font-family: Noto Sans;
    font-weight: 400;
    line-height: var(--_16px);
    max-height: unset;
    height: unset;
}
