.preview {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    gap: 0;
    height: 100%;
}

.container {
    padding: 16px 20px 0px;
    scroll-behavior: smooth;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.exerciseTitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.exerciseDescription,
.textAlert {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    color: var(--text-light-gray-secondary);
}

.buttonsContainer {
    background: var(--white);
    padding: 5px;
    margin: auto 10px 10px;
    z-index: 1;
    border: 1px solid var(--border-light-gray);
    box-shadow: 0px 1px 4px 0px var(--black-transparent);
    border-radius: 12px;
}

.previewContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: scroll;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0px 20px 20px;
    flex-grow: 1;
}

.exerciseText {
    white-space: pre-line;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.locked {
    pointer-events: none;
}

.completeButton,
.completedText {
    margin-top: 24px;
    width: 100%;
    height: 42px;
    min-height: 42px;
    position: relative;
}

.completeButton::before,
.completedText::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--text-light-gray-secondary);
    left: 0;
    right: 0;
    top: -16px;
}

.completedText {
    text-align: center;
    min-height: unset;
}

.teacherFeedback {
    display: flex;
    flex-direction: column;
    align-items: center;
}
