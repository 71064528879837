.cardContainer {
    margin: 0 auto;
    width: 100%;
}

.role {
    padding-bottom: 24px;
    text-align: center;
    font-weight: 500;
    font-size: var(--_28px);
    line-height: var(--_32px);
}

.featureTable {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background: var(--white);
    z-index: 1;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 26.9px 0px var(--landing-transparent-shadow);
}

.featureTable th,
.featureTable td {
    padding: 8px;
    padding-left: 16px;
    height: 40px;
}

.featureTable th {
    font-size: var(--_16px);
    line-height: var(--_20px);
}

.featureTable td {
    font-size: var(--_16px);
    line-height: var(--_20px);
    border-bottom: 1px solid var(--dialogue-accent-gray);
    font-weight: 400;
    color: #1c1c1eb2;
    white-space: nowrap;
}

.featureTable tr:last-child td {
    border: none;
}

.featureTable th {
    background: var(--dialogue-progress-purple);
    font-weight: 400;
}

.iconCell {
    width: 20%;
    text-align: center;
}

.icon {
    margin: 0 auto;
    min-width: var(--_14px);
    min-height: var(--_14px);
    max-height: var(--_14px);
    max-width: var(--_14px);
}
