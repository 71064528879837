.substitutionContainer {
    display: flex;
    gap: 12px;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
}

.exerciseText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    gap: 4px;
    user-select: none;
    flex-grow: 1;
    overflow: scroll;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0 20px;
}

.wordContainer {
    display: flex;
    align-items: center;
}

.gap {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 50px;
    height: 19px;
    padding: 1px 9px;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    transition: all 0.2s;
    border: 1px solid transparent;
}

.answers {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    min-height: fit-content;
}

.answers.pdf {
    pointer-events: none;
}

.answer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--primary-light-gray);
    padding: 1px 9px;
    user-select: none;
    transition: all 0.2s;
}

.gap.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
    pointer-events: none;
}

.gap.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
    pointer-events: none;
}

.gap.pdf {
    min-width: 100px;
    height: 24px;
}

@media (hover: hover) {
    .answer:hover,
    .gap:hover {
        background-color: var(--secondary-light-gray);
    }
}

@media print {
    .gap.pdf {
        break-inside: avoid;
    }
}
