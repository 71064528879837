* {
    font-family: Noto Sans;
}

.firstSection {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    height: 90vh;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    user-select: none;
    position: relative;
}

.backgroundContainer {
    position: absolute;
    top: -10%;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.backgroundImage {
    min-width: 900px;
    max-width: 900px;
    min-height: 900px;
    max-height: 900px;
}

.sectionInner {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--_32px);
    justify-content: center;
    align-items: center;
    position: relative;
}

.firstSection .sectionInner .mainTitle {
    font-size: var(--_50px);
    font-weight: 500;
    line-height: var(--_58px);
    letter-spacing: -0.02em;
    z-index: 1;
}

.subTitleSection {
    display: flex;
    align-items: center;
}

.subTitle {
    font-size: var(--_24px);
    font-weight: 400;
    letter-spacing: -0.02em;
}

.subTitleSelection {
    display: flex;
    border-radius: var(--_12px);
    padding: var(--_3px);
    padding-left: var(--_12px);
    align-items: center;
    font-family: Noto Sans;
    font-weight: 400;
}

.subTitleSelection p {
    font-size: var(--_24px);
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #1c1c1eb2;
    text-transform: lowercase;
}

.subTitleSelection p:first-child {
    text-transform: none;
}

.subTitleSelection p.selected {
    border-radius: var(--_10px);
    padding: var(--_3px) var(--_9px);
    font-size: var(--_24px);
    font-weight: 500;
    line-height: var(--_30px);
    letter-spacing: -0.02em;
    filter: drop-shadow(0 3px 3px #3030360a);
    color: var(--black-hover);
    text-transform: uppercase;
    font-weight: 500;
}
