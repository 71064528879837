.categoryCard {
    width: calc(100% + 24px);
    margin-left: -12px;
    height: min-content;
    box-sizing: border-box;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    transition: all 0.1s;
    position: relative;
    cursor: pointer;
    margin-bottom: -12px;
}

.categoryCard:last-child {
    margin-bottom: 0;
}

.preview {
    width: 100px;
    min-width: 100px;
    height: 56px;
    border-radius: 8px;
    position: relative;
    background: var(--white);
}
.list {
    overflow: hidden scroll;
    flex-grow: 1;
}

.cardInner {
    display: flex;
    flex-grow: 1;
    max-width: calc(100% - 116px);
    overflow: visible;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    position: relative;
    height: fit-content;
}

.title {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 20px;
    min-height: 20px;
    text-align: left;
}

.title span {
    color: var(--text-light-gray);
    text-transform: uppercase;
    margin-left: 4px;
    font-size: 16px;
    line-height: 20px;
}

.preview .folder,
.preview .count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.preview .count {
    top: calc(50% + 5px);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--words-green);
}

.words {
    display: flex;
    gap: 3px 6px;
    flex-wrap: wrap;
}

.word {
    background: var(--primary-light-gray);
    padding: 3px 6px;
    border-radius: 6px;
    color: var(--text-light-gray-secondary);
}

.emptyAlert {
    width: calc(100% + 20px);
    margin-left: -10px;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 80%;
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
}

.alertTitle,
.alertSubtitle {
    text-align: center;
    color: var(--text-light-gray-secondary);
}

.alertTitle {
    margin-top: 26px;
    font-size: 32px;
    font-weight: 400;
    line-height: 43.58px;
}

.alertSubtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

@media (hover: hover) {
    .categoryCard:hover {
        background: var(--light-gray);
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
