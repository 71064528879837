.answersCont {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    cursor: grab;
    overflow-y: scroll;
}

.answersCont.blocked {
    pointer-events: none;
}

.answersCont::-webkit-scrollbar {
    width: 0;
}

.sentenceCont {
    display: inline;
    width: max-content;
    max-width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    background: var(--primary-light-gray);
    color: var(--text-light-gray-secondary);
    padding: 6px 12px;
    border-radius: 8px;
    border: 1px solid transparent;
    margin-bottom: 10px;
    padding-left: 30px;
    position: relative;
}

.sentenceCont.correct {
    background: var(--green-transparent);
    border: 1px solid var(--text-light-green);
    color: var(--black);
}

.sentenceCont.wrong {
    background: var(--primary-transparent-red);
    border: 1px solid var(--text-light-red);
    color: var(--black);
}

.sentenceCont.pdf {
    pointer-events: none;
    cursor: default;
}

.sentenceCont.pdf::before {
    width: 18px;
    height: 18px;
    border: 1px solid var(--text-light-gray-secondary);
    border-radius: 25%;
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0%, -50%);
    background: var(--white);
}

.sentenceCont svg {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 18px;
}
