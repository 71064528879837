.quizEditor,
.answers {
    display: flex;
    flex-direction: column;
}

.quizEditor {
    row-gap: 25px;
}

.questionCont {
    position: relative;
}

.answers {
    row-gap: 5px;
}

.answerInner {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.answerInput {
    flex: auto;
    position: relative;
}

.questionNumber {
    position: absolute;
    left: 0;
    top: 3px;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: var(--dark-gray);
}

.question {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    margin-left: 20px;
    width: calc(100% - 45px);
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    padding: 3px 32px 3px 8px;
    overflow-y: hidden;
    transition: background-color 0.1s;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    min-height: 25px;
    line-height: 19px;
    background: var(--primary-light-gray);
}

.question:focus {
    background: var(--white);
}

.question svg {
    height: 12px;
}

.option {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    width: 100%;
    border: 0.3px solid var(--border-light-gray);
    border-radius: 8px;
    padding: 3px 32px 3px 8px;
    overflow-y: hidden;
    transition: background-color 0.1s;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 12px;
    min-height: 25px;
    line-height: 19px;
    background: var(--primary-light-gray);
}

.option:focus {
    background: var(--white);
}

.option svg {
    height: 12px;
}
