* {
    user-select: none;
}

.lessonsExampleContainer {
    margin: var(--_150px) auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    position: relative;
    padding: 0 var(--_24px);
}

.title {
    font-size: var(--_36px);
    font-weight: 500;
    line-height: var(--_42px);
    letter-spacing: -0.02em;
    margin-bottom: var(--_12px);
}

.subTitle {
    font-size: var(--_16px);
    font-weight: 400;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    max-width: 50vw;
    color: #1c1c1e80;
    margin-bottom: var(--_48px);
}

.subTitle span {
    font-size: var(--_16px);
    font-weight: 500;
    line-height: var(--_22px);
    letter-spacing: -0.02em;
    color: var(--black);
}

.cardsBlock {
    display: flex;
    gap: var(--_16px);
    width: 100%;
    max-width: 1680px;
    justify-content: center;
}

.lessonsExampleContainer .logoEN {
    width: 14vw;
    height: 14vw;
    top: 0%;
    left: -4%;
    opacity: 0.5;
    z-index: -1;
}

.lessonsExampleContainer .logoIT {
    width: 24vw;
    height: 24vw;
    top: -15%;
    right: -8%;
    left: unset;
    z-index: -1;
}

.cardWrapper {
    max-width: calc(25% - 12px);
    max-height: 60vh;
    border-radius: var(--_12px);
}
