.contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 16px;
    overflow: hidden;
    flex-grow: 1;
    padding-bottom: 16px;
}

.aside {
    width: 100%;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.aside > * {
    width: 100%;
    max-width: unset;
}

.contentContainer:not(.round) .aside > :nth-child(1) {
    border-radius: 16px 16px 0 0;
    border-bottom: none;
}

.contentContainer:not(.round) .aside > :nth-child(2) {
    border-radius: 0 0 16px 16px;
    border-top: none;
}

.main {
    width: 100%;
    max-width: unset;
}
