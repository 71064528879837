.cardContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 12px;
}

.card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px 14px;
    gap: 8px;
    border-radius: 8px;
    border: 1px dashed var(--border-light-gray);
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
}

.card.disabled {
    filter: grayscale();
    opacity: 0.5;
    cursor: not-allowed;
}

.cardText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
    height: 120px;
    justify-content: center;
    overflow: visible;
}

.cardText > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-transform: capitalize;
    margin: 0;
}

.cardText > span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: var(--text-light-gray-secondary);
    white-space: pre-line;
    margin: 0;
}

.iconContainer {
    position: relative;
}

.count {
    position: absolute;
    bottom: -6px;
    left: 100%;
    font-weight: 700;
}

.cardContainer.words .count {
    color: var(--words-green);
}

.cardContainer.track .count {
    color: var(--purple);
}

.cardContainer.situation .count {
    color: var(--orange);
}

.cardContainer.exercise .count {
    color: var(--blue);
}

.strokeIcon,
.fillIcon {
    width: 40px;
    height: 40px;
}

.cardContainer.words .strokeIcon path {
    stroke: var(--words-green);
}

.cardContainer.words .fillIcon path {
    fill: var(--words-green);
}

.cardContainer.track .strokeIcon path {
    stroke: var(--purple);
}

.cardContainer.track .fillIcon path {
    fill: var(--purple);
}

.cardContainer.situation .strokeIcon path {
    stroke: var(--orange);
}

.cardContainer.situation .fillIcon path {
    fill: var(--orange);
}

.cardContainer.exercise .strokeIcon path {
    stroke: var(--blue);
}

.cardContainer.exercise .fillIcon path {
    fill: var(--blue);
}

@media (hover: hover) {
    .card:hover {
        border-style: solid;
    }
    .cardContainer.words .card:hover {
        background: var(--words-green-transparent-hover);
    }
    .cardContainer.track .card:hover {
        background: var(--light-purple);
    }
    .cardContainer.exercise .card:hover {
        background: var(--blue-transparent);
    }
    .cardContainer.situation .card:hover {
        background: var(--orange-transparent);
    }
}
