.textDisplay {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    width: calc(100% + 12px);
    margin-left: -12px;
    height: 100%;
    flex-grow: 1;
    position: relative;
    background: var(--black);
}

.currentSentence {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4.6px;
    margin: 0;
    padding: 3px;
    position: relative;
    align-items: flex-start;
    line-height: 1.3;
    cursor: pointer;
}

.currentSentence.blur {
    filter: blur(5px);
}

.word {
    color: var(--transparent-gray);
    font-family: Noto Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.word.active {
    color: var(--white);
}

.eyeButton {
    width: 36px;
    height: 36px;
    max-height: 36px;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 12px;
    opacity: 1;
    background: var(--text-light-gray-secondary);
}

.eyeButton svg {
    height: 16px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.eyeButton path {
    stroke: var(--white);
}
